import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import { Card, Toolbar } from 'Components';
import { ConfigItemsStorage } from 'Utils';

const styles = css`
  margin: 0 16px 32px;

  > #cards-container {
    display: grid;
    gap: 16px;
    margin-top: 16px;
  }

  @media (min-width: 600px) and (max-width: 1279px) {
    > #cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1280px) {
    > #cards-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

const putItemFirstInMemory = (clickedItem: ConfigItem) => () => {
  const itemsInMemory = ConfigItemsStorage.get();
  const newItems = [clickedItem, ...itemsInMemory.filter((item) => item.secret !== clickedItem.secret)];
  ConfigItemsStorage.set(newItems);
};

export const App: React.FC = () => {
  const [items, setItems] = useState(ConfigItemsStorage.get);
  const cardsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardsContainer.current) {
      cardsContainer.current.scrollIntoView();
      window.scrollBy({ top: -16 });
    }
  }, []);

  const handleFileLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      console.warn('No file selected.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target && event.target.result && typeof event.target.result === 'string') {
        const newItems = JSON.parse(event.target.result);
        setItems(newItems);
        ConfigItemsStorage.set(newItems);
      }
    };

    reader.readAsText(files[0]);
  };

  return (
    <div className={styles}>
      <Toolbar onIconClick={handleFileLoad} />

      <div id="cards-container" ref={cardsContainer}>
        {items.map((item) => (
          <Card key={`${item.issuer}-${item.name}`} account={item} onClick={putItemFirstInMemory(item)} />
        ))}
      </div>
    </div>
  );
};
