import React, { useEffect, useState } from 'react';
import { css, cx } from '@emotion/css';

const styles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;

  > svg {
    height: 24px;
    width: 24px;
  }
`;

interface Props {
  issuer: string;
  className?: string;
}

export const Favicon: React.FC<Props> = ({ issuer, className }) => {
  const [iconModule, setIconModule] = useState<React.FC<React.SVGProps<SVGSVGElement>>>();

  useEffect(() => {
    import(`!!@svgr/webpack?-svgo,+ref!Assets/issuers/${issuer.toLowerCase()}.svg`)
      .then((module) => setIconModule(module.default))
      .catch(() => console.warn(`Icon with name: ${issuer} not found!`));
  }, [issuer]);

  if (!iconModule) {
    return null;
  }

  const Icon = iconModule;
  return (
    <div className={cx(styles, className)}>
      <Icon />
    </div>
  );
};
