import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import { Favicon, Timer, Title } from 'Components';
import { authenticator } from 'otplib';
import { copyToClipboard } from 'Utils';
import { useTimeLeft } from 'Hooks';

const styles = css`
  width: 100%;
  background-color: #1b1c1e;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  overflow: hidden;

  .top {
    display: flex;
    align-items: center;
    color: #979797;
    height: 24px;

    > * + * {
      margin-left: 8px;
    }
  }

  > .code {
    margin-top: 8px;
  }

  > .account-name {
    color: #979797;
  }

  > .timer {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  > .copied {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    background-color: rgba(0, 0, 0, 0.9);

    opacity: 0;
    transition: opacity ease-in-out 250ms;

    &.visible {
      opacity: 1;
    }
  }
`;

interface Props {
  account: ConfigItem;
  onClick?: () => void;
}

export const Card: React.FC<Props> = ({ account, onClick = () => null }) => {
  const [clicked, setClicked] = useState(false);
  const timeLeft = useTimeLeft();

  const { issuer, name, secret } = account;
  const code = authenticator.generate(secret);

  const handleClick: React.MouseEventHandler<HTMLElement> = () => {
    copyToClipboard(code);
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
    onClick();
  };

  return (
    <div className={styles} onClick={handleClick}>
      <div className="top">
        <Favicon issuer={issuer} />
        <Title value={issuer} subtitle />
      </div>

      <Title className="code" value={`${code.slice(0, 3)} ${code.slice(3, 6)}`} />
      <Title className="account-name" value={name} subtitle />

      <div className="timer">
        <Timer key={`${issuer}-${name}-${code}`} timeLeft={timeLeft} />
      </div>

      <div className={cx('copied', clicked && 'visible')}>Copié !</div>
    </div>
  );
};
