import React, { ChangeEventHandler } from 'react';
import { css, cx } from '@emotion/css';
import { ReactComponent as ImportIcon } from 'Assets/import.svg';

const styles = css`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px 0;

  > div {
    color: #979797;
    flex: 1;
    margin-right: 16px;
    padding-bottom: 4px;
    border-bottom: 1px solid #979797;
  }

  > label {
    width: 32px;
    height: 32px;

    > input {
      display: none;
    }
  }
`;

interface Props {
  onIconClick: ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

export const Toolbar: React.FC<Props> = ({ onIconClick, className }) => (
  <div className={cx(styles, className)}>
    <div>Search todo</div>

    <label>
      <ImportIcon width={32} height={32} fill="#979797" />
      <input type="file" accept="application/json" onChange={onIconClick} />
    </label>
  </div>
);
