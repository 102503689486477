import { useEffect, useState } from 'react';
import { authenticator } from 'otplib';

export const useTimeLeft = (): number => {
  const [timeLeft, setTimeLeft] = useState(authenticator.timeRemaining());

  useEffect(() => {
    const id = setInterval(() => setTimeLeft(authenticator.timeRemaining()), 500);

    return () => clearInterval(id);
  });

  return timeLeft;
};
