import React from 'react';
import { css, cx } from '@emotion/css';

const titleStyles = css`
  font-size: 44px;
  line-height: 44px;
`;

const subtitleStyles = css`
  font-size: 16px;
`;

interface Props {
  value: string;
  subtitle?: boolean;
  className?: string;
}

export const Title: React.FC<Props> = ({ value, subtitle = false, className }) => (
  <span className={cx(subtitle ? subtitleStyles : titleStyles, className)}>{value}</span>
);
