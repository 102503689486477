import React from 'react';
import { css, cx } from '@emotion/css';
import { authenticator } from 'otplib';

const styles = css`
  width: 8px;
  transition: height linear 1s, background-color linear 1s;
`;

const percentageStyles = (percent: number): string => css`
  height: ${percent}%;
  background-color: ${percent <= 34 ? 'red' : 'dodgerblue'};
`;

const maxTime = authenticator.allOptions().step;

interface Props {
  timeLeft: number;
}

export const Timer: React.FC<Props> = ({ timeLeft }) => <div className={cx(styles, percentageStyles((timeLeft / maxTime) * 100))} />;
